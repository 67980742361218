.UsersPhoneInput .form-control {
  width: 100% !important;
  height: 40px !important;
}
.UserDetailName,
.UserDetailType,
.UserDetailBirthDateText,
.UserDetailBirthDateData {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  /* Primary Text */

  color: #303134;
}
.UserDetailType {
  font-size: 14px;
  line-height: 17px;
  color: #828282;
}
.UserDetailBirthDateText {
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;
}
.UserDetailBirthDateData {
  font-size: 14px;
  line-height: 24px;

  font-weight: 400 !important;
}

.UserDetailDetailsText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
}
.UserDivider {
  margin: 0px !important;
}
.UserDetailIndustryText,
.UserDetailIndustryTextData {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
}

.UserDetailIndustryTextData {
  font-weight: 400;
}
.userdetails{ 
  height: 56px;
  background:#F5F6F8;
   padding: 16px;
}
